import * as React from 'react';
import { Container, Grid, Typography, Toolbar, Box, AppBar } from '@mui/material';

import MainText from '../../../components/TypeAnimation/MainText';

function MobileHeaderSection() {
    return (
        <>
            <Box>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ width: 1 }}>
                            <img src="/office.webp" alt="office" style={{ width: '100%', height: '80vh', objectFit: 'cover', boxShadow: 'inset 0px -80px 61px -37px rgba(66, 68, 90, 1)' }} />

                            <Container>
                                <MainText variant="body" />
                            </Container>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ position: 'absolute', top: 0, width: '100%' }}>
                <Container>
                    <AppBar position="">
                        <Toolbar>
                            <img src="logo.png" alt="Logo" style={{ width: '36px' }} />
                        <Typography variant="h6" component="div" sx={{ ml: 2 }}>
                            FrogIt
                        </Typography>
                        <Typography variant="h6" component="div" sx={{ ml: 2 }}>
                            News
                        </Typography>
                        </Toolbar>
                    </AppBar>
                </Container>
            </Box>
        </>
    );
}

export default MobileHeaderSection;