import { Container } from '@mui/material';
import './App.css';
import CustomerSection from './Sections/CustomerSection/CustomerSection';
import PersonaSection from './Sections/CustomerSection/PersonaSection';
import HeaderSection from './Sections/HeaderSection/Desktop/DesktopHeaderSection';
import MobileHeaderSection from './Sections/HeaderSection/Mobile/MobileHeaderSection';

function App() {
  var deviceWidth = window.innerWidth;
  
  return (
    <>
      {deviceWidth > 414 ? <HeaderSection /> : <MobileHeaderSection /> }

      <CustomerSection />

      <PersonaSection />

      <Container sx={{ height: '30rem' }}>
        
      </Container>
    </>
  );
}

export default App;
