import * as React from 'react';
import { Typography } from '@mui/material';

import { TypeAnimation } from 'react-type-animation';

function MainText({variant = "h4"}) {
    console.log(variant);
    return (
        <Typography variant={variant}>
            <TypeAnimation
                preRenderFirstString={true}
                sequence={[
                    // Same substring at the start will only be typed once, initially
                    'We produce software for Cloud.',
                    2000,
                    'We produce software for premise solutions.',
                    2000,
                    'We produce software for your business.',
                    2000,
                    'We produce software for your next idea.',
                    2000,
                ]}
                speed={45}
                style={{ 
                    fontSize: '2em',
                    height: '200px',
                    width: '100%',
                    display: 'block'
                }}
                repeat={Infinity}
            />
        </Typography>
    );
}

export default MainText;