import * as React from 'react';
import { Container, Typography, Box, Card, CardContent, Avatar } from '@mui/material';

function PersonaSection() {
    return (
        <Box sx={{ backgroundColor: '#ecfdf5', color: 'black', pt: 1, pb: 2 }}>
            <Container sx={{ mt: 4, mb: 4 }}>
                <Typography variant='h3' sx={{  }}>
                    What is our perfect customer?
                </Typography>
            </Container>

            <Container sx={{ mb: 4, display: 'flex', justifyContent: 'center', '& > *': { mx: 2 } }}>                
                    <Card sx={{ maxWidth: '24rem' }}>
                        <CardContent sx={{ '& > *': {
                            my: 2,
                        } }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Avatar
                                    alt="entry level customer"
                                    src="/entryLevel.webp"
                                    sx={{ width: 256, height: 256 }}
                                />
                            </Box>
                            <Typography variant='h4' paragraph>Begginer of digitalization</Typography>
                            <Typography variant='body' fontWeight={300} paragraph>
                                If you are a person that starts journey towards digitalization and want to automate some of your daily routines, or just have new and useful tools for daily work, we would be perfect joice to solve your problems!
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ maxWidth: '24rem' }}>
                        <CardContent sx={{ '& > *': { my: 2 } }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Avatar
                                    alt="advanced customer"
                                    src="/highLevel.webp"
                                    sx={{ width: 256, height: 256 }}
                                />
                            </Box>
                            <Typography variant='h4' paragraph>Champion of change</Typography>
                            <Typography variant='body' fontWeight={300} paragraph>
                                As you company grows, your challanges are growing too? You find yourself in a situations when you think "hmm if we had an application for that...", or "I wish we had some IT background to make our life simple". You couldn't land on a better page than this!
                            </Typography>
                        </CardContent>
                    </Card>
            </Container>
        </Box>
    );
}

export default PersonaSection;