import * as React from 'react';
import { Container, Grid, Typography, Toolbar, Box, AppBar } from '@mui/material';

import MainText from '../../../components/TypeAnimation/MainText';

function DesktopHeaderSection() {
    return (
        <>
            <Box>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ width: 1 }}>
                            <img src="/office.webp" alt="office" style={{ width: '100%', minHeight: '400px' }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', minHeight: '400px', p:4 }}>
                            <MainText variant="h4" />
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ position: 'absolute', top: 0, width: '100%' }}>
                <Container>
                    <AppBar position="">
                        <Toolbar>
                            <img src="logo.png" alt="Logo" style={{ width: '36px' }} />
                        <Typography variant="h6" component="div" sx={{ ml: 2 }}>
                            Frog<span style={{ color: '#beffc7' }}>i</span>t
                        </Typography>
                        <Typography variant="h6" component="div" sx={{ ml: 2 }}>
                            News
                        </Typography>
                        </Toolbar>
                    </AppBar>
                </Container>
            </Box>
        </>
    );
}

export default DesktopHeaderSection;