import * as React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';

function CustomerSection() {
    return (
        <>
            <Container sx={{ mt: 4, mb: 4 }}>
                <Typography variant='h3'>
                    With whom do we work?
                </Typography>
            </Container>

            <Container sx={{ mb: 4 }}>
                <Grid container>
                    <Grid item xs={4}>
                        <img src="/smallBusiness.webp" alt="small business" style={{ width: '100%', borderRadius: '180px 113px 200px 109px' }} />
                    </Grid>
                    <Grid item xs={8} sx={{ pl:8, display: 'flex', alignItems: 'center' }} fontWeight={300} fontSize={18}>
                        <Box>
                            <Typography variant='body' paragraph>
                                We love to work with small business owners just like we! We are thrilled to see how others can thrive using our software, and we truly believe that smart, well-tailored applications can really accelerate your productivity and business.
                            </Typography>                            
                            <Typography variant='body' paragraph>
                                Small application for storing data in database, calendar based solution for scheduling your visits, single page application to present yourself online? You name it, we got it!
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Container sx={{ mb: 4 }}>
                <Grid container>
                    <Grid item xs={8} sx={{ pr:8, display: 'flex', alignItems: 'center' }} fontWeight={300} fontSize={18}>
                        <Box>
                            <Typography variant='body' paragraph>
                                We've worked with several companies creating custom CMS solutions. We've made programs based on queues for faster and sequentional processing. We've integrated with multiple 3rd party systems, and many many others. We are limited only by your imagination!
                            </Typography>
                            <Typography variant='body' paragraph>
                                And as the journey to Cloud is taking place everywhere, we are prepared for those scenarios as well!
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <img src="/mediumBusiness.webp" alt="medium business" style={{ width: '100%', borderRadius: '85% 50% 53% 68%' }} />
                    </Grid>
                </Grid>
            </Container>

            <Container sx={{ mb: 4 }}>
                <Grid container>
                    <Grid item xs={4}>
                        <img src="/stairs.webp" alt="infinite stairs" style={{ width: '100%', borderRadius: '68%  85% 50%  53%' }} />
                    </Grid>
                    <Grid item xs={8} sx={{ pl:8, display: 'flex', alignItems: 'center' }} fontWeight={300} fontSize={18}>
                        <Box>
                            <Typography variant='body' paragraph>
                                We are pretty sure that one factor that limits us is just our imagination. For years learning new technologies we are able to develop your desired solutions swiflty and firmly.
                            </Typography>
                            <Typography variant='body' paragraph>
                                If you have any crazy idea or need that have to be fullfiled don't hesitate to let us know. We're here to help you grow and thrive!
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default CustomerSection;